import React, { memo, useCallback, useMemo, useState, useEffect, useRef, type CSSProperties } from 'react';
import type { DocumentNode } from 'graphql';
import type { OperationVariables, ApolloError } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';
import { useForm, type FormApi, type AnyFormValues, type ValidateResult } from 'react-form';
import { Paper, Typography } from '@popmenu/common-ui';

import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { toQueryName } from '../../../../utils/apollo';
import useMemoDeep from '../../../../utils/useMemoDeep';
import { useSnackbar } from '../../../../utils/withSnackbar';
import useVariable from '../../../../utils/useVariable';
import { useIntl } from '../../../../utils/withIntl';
import { buildErrorMessage } from '../../../../utils/i18n';
import usePrevious from '../../../../utils/usePrevious';

import BasicFormConfirmModal from './BasicFormConfirmModal';
import { AH, AHLevelProvider } from '../../../../consumer/shared/AccessibleHeading';

export interface BasicFormApi<TFormValues> extends Pick<
  FormApi<TFormValues>,
  'pushFieldValue' | 'removeFieldValue' | 'setFieldMeta' | 'setFieldValue' | 'setMeta' | 'setValues' | 'values'
> {
  loading: boolean;
  setValue: FormApi<TFormValues>['setFieldValue'];
  submitForm: (customValues?: Partial<TFormValues>) => void;
  submitManual: (customValues: TFormValues) => void;
}

/* eslint-disable react/require-default-props */
interface BasicFormSharedProps<TFormValues> {
  children: React.ReactNode | ((basicFormApi: BasicFormApi<TFormValues>) => React.ReactNode);
  'aria-label'?: MaybeFormattedMessage | null;
  'aria-labelledby'?: string;
  confirm?: { message?: string; title?: string } | null;
  'data-cy'?: string;
  id?: string;
  title?: MaybeFormattedMessage | null;
  titleProps?: Partial<React.ComponentProps<typeof AH>>;
}
/* eslint-enable react/require-default-props */

interface BasicFormInnerProps<TFormValues> extends BasicFormSharedProps<TFormValues> {
  ariaErrorMessage: string | null;
  basicFormApi: BasicFormApi<TFormValues>;
  closeModal: () => void;
  confirmModalOnSubmit: () => void;
  Form: typeof React.Component;
  loading: boolean;
  paper: boolean;
  shadow: boolean;
  showModal: boolean;
  style: CSSProperties;
  valid: boolean;
  validationCount: number;
}

const BasicFormInner = memo(<TFormValues = AnyFormValues>(props: BasicFormInnerProps<TFormValues>) => {
  const {
    ariaErrorMessage,
    basicFormApi,
    children,
    closeModal,
    confirm,
    confirmModalOnSubmit,
    Form,
    id,
    paper,
    shadow,
    showModal,
    title,
    titleProps,
  } = props;
  const elementProps: { legacyStyles?: boolean, shadow?: boolean } = {};
  if (paper) {
    elementProps.legacyStyles = true;
    elementProps.shadow = shadow;
  }

  return (
    <React.Fragment>
      {React.createElement(paper ? Paper : React.Fragment, elementProps, (
        <React.Fragment>
          {title && (
            <AH typography style={{ marginBottom: '24px' }} variant="h6" {...titleProps}>
              {title}
            </AH>
          )}
          <AHLevelProvider>
            <Form
              aria-label={props['aria-label'] || title}
              aria-labelledby={props['aria-labelledby']}
              id={id}
              data-cy={props['data-cy']}
              style={props.style}
            >
              <React.Fragment>
                {typeof children === 'function' ? children(basicFormApi) : children}
                {ariaErrorMessage && (
                  <div
                    aria-atomic="true"
                    aria-live="assertive"
                    className="sr-only"
                    role="alert"
                  >
                    {ariaErrorMessage}
                  </div>
                )}
              </React.Fragment>
            </Form>
          </AHLevelProvider>
        </React.Fragment>
      ))}
      {!!confirm && (
        <BasicFormConfirmModal
          closeModal={closeModal}
          message={confirm.message}
          onSubmit={confirmModalOnSubmit}
          showModal={showModal}
          title={confirm.title}
        />
      )}
    </React.Fragment>
  );
}) as <TFormValues = AnyFormValues>(props: BasicFormInnerProps<TFormValues>) => React.JSX.Element;

export interface BasicFormProps<TFormValues = AnyFormValues, ParamsType = unknown> extends BasicFormSharedProps<TFormValues> {
  delayedQueryWait?: number;
  defaultValues?: TFormValues;
  formErrorClassName?: string;
  // Form submission handling (GQL mutation)
  mutate?: {
    ignoreAbortError?: boolean;
    // the gql mutation imported from libs
    mutation: DocumentNode;
    // callback function executed after successful mutation
    onCompleted?: (data: unknown, values: TFormValues) => void;
    onError?: (error: unknown) => void;
    // array of gql queries to refetch after successful mutation
    refetchQueries?: DocumentNode[];
    // function for transforming form values into mutation variables
    toVariables?: (values: TFormValues) => OperationVariables;
  };
  mutation?: DocumentNode | null;
  // Returning exactly false cancels submit. Returning anything else (including undefined or true) submits.
  onSubmit?: ((values: TFormValues) => boolean | undefined | void) | null; // eslint-disable-line @typescript-eslint/no-invalid-void-type
  paper?: boolean;
  shadow?: boolean;
  style?: CSSProperties;
  submitFormOnChange?: boolean;
  title?: MaybeFormattedMessage | null;
  // Like react-form validation, (non-empty) string is an error message, false/null means "no error".
  // Unlike it, `undefined` currently also means "no error" instead of "don't change the validation state".
  // `true` is an error without a message, used when the error message is already displayed in the snackbar.
  validateForm?: ((values: TFormValues, params: Optional<ParamsType>) => ValidateResult | true) | null;
  validateParams?: ParamsType | null;
}

// Needed to avoid re-rendering BasicFormInner due to `= {}` in props creating a new object every time
const emptyObject = {};

const BasicForm = <TFormValues = AnyFormValues, ParamsType = unknown>({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'data-cy': dataCy,
  children,
  confirm = null,
  defaultValues: propsDefaultValues = emptyObject as TFormValues,
  delayedQueryWait = 2000,
  formErrorClassName = undefined,
  id = undefined,
  mutate = undefined,
  mutation: propsMutation = null,
  onSubmit: propsOnSubmit = null,
  paper = false,
  shadow = false,
  style = emptyObject,
  submitFormOnChange = false,
  title = null,
  titleProps = emptyObject,
  validateForm = null,
  validateParams = null,
}: BasicFormProps<TFormValues, ParamsType>) => {
  const { showSnackbarError } = useSnackbar();
  const {
    onCompleted,
    onError,
    refetchQueries,
    toVariables,
    ignoreAbortError,
  } = mutate ?? {};
  const mutation = propsMutation || mutate?.mutation;

  // Confirmation modal state
  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);
  const openModal = useCallback(() => setShowModal(true), [setShowModal]);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  // abort any pending queries if a new one is made (enables only one query at a time)
  const abortController = useRef<AbortController | null>(null);
  // Mutation error messages displayed as hidden `role="alert"` prompt within the form
  // because when in forms mode, screen readers do not read out the snackbar
  const [ariaErrorMessage, setAriaErrorMessage] = useState<string | null>(null);
  const t = useIntl();

  // Display Snackbar error, set ARIA error for screen readers, and trigger custom error handling
  const showError = useCallback((err: unknown) => {
    if (!!ignoreAbortError && (err as ApolloError).networkError?.message === 'signal is aborted without reason') return;

    console.warn('[POPMENU] BasicForm showError:', err);
    showSnackbarError(err);
    setAriaErrorMessage(buildErrorMessage({ err, tWithIntl: t }));
    if (typeof onError === 'function') {
      onError(err);
    }
  }, [onError, setAriaErrorMessage, showSnackbarError, t, ignoreAbortError]);

  const onSubmit = useCallback((
    values: TFormValues,
    formApiSetLoading: ((loading: boolean) => void) | FormApi<TFormValues> | null = null,
    ctrl?: AbortController,
    paramsToValidate?: Optional<ParamsType>,
  ) => {
    // handle custom validation first
    if (typeof validateForm === 'function') {
      const validateResult = validateForm(values, paramsToValidate);
      if (validateResult) {
        setFormErrorMessage(validateResult === true ? null : validateResult);
        return undefined;
      } else {
        setFormErrorMessage(null);
      }
    }

    // Allow custom onSubmit handling & cancel if the callback returns false
    if (typeof propsOnSubmit === 'function') {
      if (propsOnSubmit(values) === false) {
        return undefined;
      }
    }

    // Manually set isSubmitting state if onSubmit is called outside handleSubmit
    const setLoading = (loading: boolean) => {
      if (typeof formApiSetLoading === 'function') {
        formApiSetLoading(loading);
      }
    };

    // Mutation submission
    if (mutation) {
      const variables = typeof toVariables === 'function' ? toVariables(values) : values as OperationVariables;

      if (!submitFormOnChange) {
        if (abortController.current) {
          abortController.current.abort();
        }
        abortController.current = new AbortController();
      }
      setLoading(true);
      return getApolloClient().then(client => client.mutate({
        awaitRefetchQueries: true,
        context: { fetchOptions: { signal: ctrl?.signal || abortController?.current?.signal } },
        mutation,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- using filterNonNullish increases bundle size undesirably
        refetchQueries: (refetchQueries || []).map(query => toQueryName(query)!),
        variables,
      }).then(({ data }) => {
        setLoading(false);
        if (typeof onCompleted === 'function') {
          setTimeout(() => {
            onCompleted(data, values);
          }, 0);
        }
      }).catch((err) => {
        setLoading(false);
        showError(err);
      }));
    }

    // Print to console while debugging
    console.log('[POPMENU] BasicForm values:');
    return undefined;
  }, [mutation, onCompleted, propsOnSubmit, refetchQueries, showError, toVariables, validateForm, submitFormOnChange]);
  const handleSubmit = confirm ? openModal : onSubmit;

  // Init form
  // Form is reinitialized whenever a new defaultValues object is provided, so memoize the provided value
  // Deconstruct and separately memoize needed formApi values/functions
  const defaultValues = useMemoDeep(() => propsDefaultValues, [propsDefaultValues]);
  const formApi = useForm({
    defaultValues,
    onSubmit: handleSubmit,
  });
  /* eslint-disable @typescript-eslint/unbound-method */
  const {
    Form,
    pushFieldValue,
    removeFieldValue,
    setFieldMeta,
    setFieldValue,
    setMeta,
    setValues,
  } = formApi;
  /* eslint-enable @typescript-eslint/unbound-method */
  const values = useMemoDeep(() => formApi.values, [formApi.values]);
  const loading = formApi.meta.isSubmitting;
  const formApiSetLoading = useCallback((isSubmitting: boolean) => {
    setMeta({ isSubmitting });
  }, [setMeta]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSubmit = useCallback(
    debounce(
      (formValues: TFormValues, ctrl: AbortController, paramsToValidate: Optional<ParamsType>) => (
        onSubmit(formValues, formApiSetLoading, ctrl, paramsToValidate)
      ),
      delayedQueryWait,
    ),
    [delayedQueryWait],
  );

  const delayedQuery = useCallback((formValues: TFormValues, paramsToValidate: Optional<ParamsType>) => {
    abortController?.current?.abort();

    const ctrl = new AbortController();
    abortController.current = ctrl;
    void debouncedOnSubmit(formValues, ctrl, paramsToValidate);
  }, [debouncedOnSubmit]);

  const prevValues = usePrevious(values);

  useEffect(() => {
    if (!prevValues || isEqual(values, prevValues) ||
      ((prevValues as { id?: unknown }).id !== (values as { id?: unknown }).id)
    ) {
      return;
    }
    if (submitFormOnChange) {
      delayedQuery(values, validateParams);
    }
  }, [values, prevValues, delayedQuery, submitFormOnChange, validateParams]);
  // Preserve previous validation state while validating to prevent unnecessary renders
  // react-form always sets isValid to false while validating
  const [lastValid, setLastValid] = useVariable(true);
  const [lastFieldsAreValidating, setLastFieldsAreValidating] = useVariable(false);
  const [lastValidationCount, setLastValidationCount] = useVariable(0);
  let valid: boolean;
  let validationCount = lastValidationCount;
  if (formApi.meta.fieldsAreValidating) {
    valid = lastValid;
  } else {
    valid = formApi.meta.isValid;
    setLastValid(valid);
    if (lastFieldsAreValidating !== formApi.meta.fieldsAreValidating) {
      validationCount += 1;
      setLastValidationCount(validationCount);
    }
  }
  setLastFieldsAreValidating(formApi.meta.fieldsAreValidating);

  // For function type children, provide a minimal, memoized react-form formApi w/ loading state and manual submit functions
  const basicFormApiSubmitForm = useCallback((customValues: Partial<TFormValues> = {}) => {
    void handleSubmit(
      {
        ...values,
        ...customValues,
      },
      formApiSetLoading,
    );
  }, [formApiSetLoading, handleSubmit, values]);
  const basicFormApiSubmitManual = useCallback((customValues: TFormValues) => {
    void handleSubmit(
      {
        ...customValues,
      },
      formApiSetLoading,
    );
  }, [formApiSetLoading, handleSubmit]);
  const basicFormApi = useMemo((): BasicFormApi<TFormValues> => ({
    loading,
    pushFieldValue,
    removeFieldValue,
    setFieldMeta,
    setFieldValue,
    setMeta,
    setValue: setFieldValue,
    setValues,
    submitForm: basicFormApiSubmitForm,
    submitManual: basicFormApiSubmitManual,
    values,
  }), [basicFormApiSubmitForm, basicFormApiSubmitManual, loading, pushFieldValue, removeFieldValue, setFieldMeta, setFieldValue, setMeta, setValues, values]);

  // Memoize confirmation modal submit function
  const confirmModalOnSubmit = useCallback(
    () => {
      void onSubmit(values, formApiSetLoading, undefined, validateParams);
    },
    [formApiSetLoading, onSubmit, validateParams, values],
  );

  // Only provide a slimmer set of memoized props to the rendered component
  return (
    <React.Fragment>
      <BasicFormInner<TFormValues>
        aria-label={ariaLabel || title}
        aria-labelledby={ariaLabelledBy}
        ariaErrorMessage={ariaErrorMessage}
        basicFormApi={basicFormApi}
        closeModal={closeModal}
        confirm={confirm}
        confirmModalOnSubmit={confirmModalOnSubmit}
        data-cy={dataCy}
        Form={Form}
        id={id}
        loading={loading}
        paper={paper}
        shadow={shadow}
        showModal={showModal}
        style={style}
        title={title}
        titleProps={titleProps}
        valid={valid}
        validationCount={validationCount}
      >
        {children}
      </BasicFormInner>
      {formErrorMessage && (
        <Typography className={formErrorClassName}>
          {formErrorMessage}
        </Typography>
      )}
    </React.Fragment>
  );
};

export default BasicForm;
